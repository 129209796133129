import { defineStore } from "pinia";

export const useVideoStore = defineStore("video", {
  state: () => {
    return {
      isMuted: false,
      volume: 1,
      lastWatchedTime: {},
      showSensitiveContent: {},
    };
  },
  actions: {
    updateMuteState(isMuted) {
      this.isMuted = isMuted;
    },

    updateVolume(volume) {
      this.volume = volume;
    },

    updateLastWatchedTime(userId, videoId, time) {
      // Check if the entry for the userId exists, if not initialize it as an object
      if (!this.lastWatchedTime[userId]) {
        this.lastWatchedTime[userId] = {};
      }

      // Update the last watched time for the specific video for this user
      this.lastWatchedTime[userId][videoId] = time;
    },

    updateSensitiveContent(videoId, isSensitive) {
      console.log("updateSensitiveContent", videoId, isSensitive);
      this.showSensitiveContent[videoId] = isSensitive;
    },

    resetVideoState() {
      this.isMuted = false;
      this.volume = 1;
      this.lastWatchedTime = {};
    },
  },
  persist: true,
  share: {
    enable: true,
    initialize: true,
  },
});
