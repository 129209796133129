export type Video = {
  id: string;
  title: string;
  description: string;
  tags: Array<string>;
  views: number;
  publicationDate: Date;
  authorName: string;
  username: string;
  authorId: string;
  authorProfilePicture: string;
  videoUrl: string;
  videoDuration: number;
  thumbnailUrl: string;
  commentCount: number;
  userReaction: string;
  reactions: Array<any>;
  profileEngagement: object;
  followers: number;
  upvotes: number;
  downvotes: number;
  isFollowing: Boolean | null;
  upvoted: Boolean;
  downvoted: Boolean;
  isSensitive: boolean;
  isWebOnly: boolean;
  videoType?: string;
};

export const transformToVideo = (data: any, users: any[]): Video => {
  let user = null;
  for (const u of users) {
    if (data.user.userId === u.ulid) {
      user = u;
    }
  }

  let upvotes = 0,
    downvotes = 0;
  data.postEngagement?.reactions.forEach((reaction) => {
    if (reaction.name === "thumbs_down") {
      downvotes = reaction.count;
    }

    if (reaction.name === "thumbs_up") {
      upvotes = reaction.count;
    }
  });

  let isFollowing = null;
  const upvoted = data.userReaction === "thumbs_up";
  const downvoted = data.userReaction === "thumbs_down";
  if (typeof user.ProfileEngagement !== "undefined") {
    isFollowing = user.ProfileEngagement.isFollowing;
  }

  let videoUrl =
    data.videos[0]?.url ||
    data.videoProcessing?.video?.url ||
    data.videoProcessing?.tmpUrl ||
    "";

  return {
    id: data.id,
    title: data.title,
    description: data.body,
    tags: data.tags,
    views: data.postEngagement?.views || 0,
    publicationDate: new Date(data.createdAt),
    authorName: user?.name || data.user.username,
    username: user?.username || data.user.username,
    authorId: user?.ulid || data.user.userId, // Use the user data for the author's ID
    authorProfilePicture: user?.avatar || null, // Use the user data for the author's profile picture
    videoUrl,
    videoDuration:
      data.videos[0]?.duration || data.videoProcessing?.video?.duration || 0,
    thumbnailUrl:
      data.videos[0]?.thumbnail?.url ||
      data.videoProcessing?.thumbnail?.url ||
      "",
    commentCount: data.postEngagement?.commentCount || 0,
    userReaction: data?.userReaction || "",
    videoType: data.postType || "",
    reactions: data.postEngagement?.reactions || [],
    profileEngagement: user?.ProfileEngagement || {},
    followers: user?.followers || 0,
    isSensitive: data.isSensitive || false,
    isWebOnly: data.isTrolling || false,
    upvotes,
    downvotes,
    upvoted,
    downvoted,
    isFollowing,
  };
};
