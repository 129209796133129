export const timeAgo = (date) => {
  const seconds = Math.floor((new Date() - date) / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);

  if (seconds < 60) {
    return seconds + " <span class='d'>seconds ago</span>";
  }

  if (minutes < 60) {
    return minutes === 1 ? "1 <span class='d'>m<span class='o'>inute ago</span></span>" : minutes + " <span class='d'>m<span class='o'>inutes ago</span></span>";
  }

  if (hours < 24) {
    return hours === 1 ? "1 <span class='d'>h<span class='o'>our ago</span></span>" : hours + " <span class='d'>h<span class='o'>ours ago</span></span>";
  }

  if (days < 7) {
    return days === 1 ? "1 <span class='d'>d<span class='o'>ay ago</span></span>" : days + " <span class='d'>d<span class='o'>ays ago</span></span>";
  }

  if (weeks < 4 || months === 0) {
    return weeks === 1 ? "1 <span class='d'>w<span class='o'>eek ago</span></span>" : weeks + " <span class='d'>w<span class='o'>eeks ago</span></span>";
  }

  if (months < 12) {
    return months === 1 ? "1 <span class='d'>m<span class='o'>onth ago</span></span>" : months + " <span class='d'>m<span class='o'>onths ago</span></span>";
  }

  const years = Math.floor(days / 365);
  return years === 1 ? "1 <span class='d'>y<span class='o'>ear ago</span></span>" : years + " <span class='d'>y<span class='o'>ears ago</span></span>";
};
