<template>
  <div id="bursts">
    <router-link
      class="flex items-center justify-start p-4 pb-4 space-x-4 select-none"
      :to="burstUrl"
    >
      <span class="image-container">
        <img :src="burstIcon" alt="BurstIcon" />
      </span>
      <p class="text-lg font-bold m-0 text-black dark:text-white">Bursts</p>
    </router-link>

    <!-- Updated to manage size and allow horizontal scrolling -->
    <div
      class="burst-grid mt- w-full"
      :class="!loading ? 'overflow-x-auto' : 'overflow-x-hidden mb-8'"
      @scroll="handleScroll"
    >
      <template v-if="loading">
        <BurstSkeleton v-for="n in numberOfBursts" :key="n" />
      </template>
      <template v-else>
        <div v-for="video in bursts" :key="video.id" class="videoContainer">
          <div class="w-full">
            <VideoPreview :video="video" />
          </div>
        </div>
        <div v-if="loadingMore" class="p-8 flex items-center justify-center">
          <span class="animate-spin h-5 w-5">
            <SvgHandler :icon="loadingIcon" />
          </span>
        </div>
        <div
          v-else-if="!loading && bursts.length > 0 && endOfBursts"
          class="p-8 flex items-center justify-center h-full"
        >
          <span class="text-center"> No more bursts </span>
        </div>
      </template>
    </div>

    <div v-if="!loading && bursts.length === 0" class="text-center">
      No bursts
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from "vue";
import VideoPreview from "@/components/videoPreview/VideoPreview.vue";
import BurstSkeleton from "@/components/BurstSkeleton.vue";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import burstIcon from "@/assets/playtv/icons/burst-icon.svg";
import { transformToVideo } from "@/types/Video.tsx";
import SvgHandler from "@/components/svgHandler/SvgHandler.vue";
import loadingIcon from "@/assets/playtv/icons/loading.svg";

const emits = defineEmits(["loadedVideos"]);
const props = defineProps({
  loading: {
    type: Boolean,
    required: true,
  },
  onDashboard: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const loading = computed(() => props.loading);
const loadingMore = ref(false);
const nextCursor = ref(null);
const bursts = ref([]);
const endOfBursts = ref(false);
const numberOfBursts = ref(20);

const burstUrl = computed(() => {
  return `/b/${bursts.value.length > 0 ? bursts.value[0].id : ""}`;
});

// Load videos
const loadBursts = async () => {
  if (loadingMore.value || endOfBursts.value) {
    return;
  }
  if (nextCursor.value !== null) {
    loadingMore.value = true;
  }
  try {
    const cursorParam = nextCursor.value ? `?cursor=${nextCursor.value}` : "";
    const {
      data: { data: videoDataIds, next_cursor: cursor },
    } = await apiBackendAuthAxios.get(`/bursts/all${cursorParam}`);

    numberOfBursts.value = videoDataIds.length;

    if (cursor === null) {
      endOfBursts.value = true;
    }

    nextCursor.value = cursor;

    if (videoDataIds.length === 0) {
      emits("loadedVideos");
      return;
    }

    const {
      data: { data: videoData },
    } = await apiBackendAuthAxios.post(`/posts/map`, {
      data: videoDataIds.map((v) => v.ulid),
      responseType: "videos",
    });

    const { data } = await apiBackendAuthAxios.post("/profile", {
      ulids: videoData.map((v) => v.user.userId),
    });

    if (bursts.value.length === 0) {
      bursts.value = videoDataIds
        .map((v) => v.ulid)
        .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
        .map((v1) => {
          return videoData.filter((v2) => v1 === v2.id)[0];
        })
        .map((v) => transformToVideo(v, data[0].data));
    } else {
      bursts.value = [
        ...bursts.value,
        ...videoDataIds
          .map((v) => v.ulid)
          .filter((v1) => videoData.filter((v2) => v1 === v2.id).length > 0)
          .map((v1) => {
            return videoData.filter((v2) => v1 === v2.id)[0];
          })
          .map((v) => transformToVideo(v, data[0].data)),
      ];
    }

    emits("loadedVideos");
  } catch (e) {
    console.error(e);
  } finally {
    loadingMore.value = false;
  }
};

const handleScroll = (e) => {
  const { target } = e;
  if (
    target.scrollLeft + target.clientWidth >= target.scrollWidth - 10 &&
    !loadingMore.value &&
    !loading.value &&
    nextCursor.value !== null
  ) {
    loadBursts();
  }
};

onMounted(() => {
  loadBursts();
});
</script>
<style scoped>
.burst-grid {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: minmax(
    150px,
    1fr
  ); /* Each video preview will have a minimum width of 150px */
  gap: 0.5rem; /* Adds spacing between video previews */
  align-items: start; /* Centers the videos vertically */
  padding-bottom: 1rem; /* Adds some padding for better visibility */
}

.burst-grid.overflow-x-hidden {
  white-space: nowrap; /* Prevents content wrapping during loading */
}

/* Constrains the size of each video preview */
.videoContainer {
  max-height: 90vh; /* Ensures video previews don't exceed 90% of the viewport height */
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.videoContainer video {
  height: 100%;
  max-width: 100%;
  object-fit: cover; /* Ensure video fits within the container */
}

/* Optional: Hide scrollbar in WebKit-based browsers */
.burst-grid::-webkit-scrollbar {
  display: none;
}
</style>
