import { defineStore } from "pinia";


export const useSidebarStore = defineStore("sidebar", {
  state: () => ({
    expanded: null,
  }),
  actions: {
    isExpanded() {
      if (this.expanded !== null) {
        return this.expanded;
      }

      const viewportWidth = window.innerWidth;
      const isIoS =
        // @ts-ignore
        /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
      const isAndroid = /Android/i.test(navigator.userAgent);
      const isMobile = viewportWidth < 453 || isIoS || isAndroid;
      return !isMobile;
    },
    expand() {
      this.expanded = true;
    },
    hide() {
      this.expanded = false;
    },
  },
  persist: true,
});
