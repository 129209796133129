import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "../axiosAuth.js";

// @ts-ignore
export const useUserStore = defineStore("user", {
  state: () => {
    return {
      loading: true,
      userId: "guestUser",
      username: null,
      email: null,
      phoneNumber: null,
      name: null,
      avatar: "/static/user-icon.png",
      background: null,
      bio: null,
      darkMode: null,
      accountSetup: null,
      acceptTermsOfService: null,
      notificationsRepostWithComment: null,
      notificationsPushNotifications: null,
      notificationsReaction: null,
      notificationsFollowRequest: null,
      notificationsFollowAccepted: null,
      notificationsFollow: null,
      notificationsMention: null,
      notificationsRepost: null,
      notificationsComment: null,
      notificationsVideoPublished: null,
      followers: null,
      following: null,
    };
  },
  actions: {
    logout() {
      this.loading = true;
      this.userId = null;
      this.username = null;
      this.email = null;
      this.phoneNumber = null;
      this.name = null;
      this.avatar = "/static/user-icon.png";
      this.background = null;
      this.bio = null;
      this.darkMode = null;
      this.accountSetup = null;
      this.acceptTermsOfService = null;
      this.notificationsRepostWithComment = null;
      this.notificationsPushNotifications = null;
      this.notificationsReaction = null;
      this.notificationsFollowRequest = null;
      this.notificationsFollowAccepted = null;
      this.notificationsFollow = null;
      this.notificationsMention = null;
      this.notificationsRepost = null;
      this.notificationsComment = null;
      this.notificationsVideoPublished = null;
      this.followers = null;
      this.following = null;
    },
    setUserState({
      userId,
      username,
      email,
      phoneNumber,
      name,
      avatar,
      background,
      bio,
      darkMode,
      accountSetup,
      acceptTermsOfService,
      notificationsRepostWithComment,
      notificationsPushNotifications,
      notificationsReaction,
      notificationsFollowRequest,
      notificationsFollowAccepted,
      notificationsFollow,
      notificationsMention,
      notificationsRepost,
      notificationsComment,
      notificationsVideoPublished,
      followers,
      following,
    }) {
      this.loading = false;
      this.userId = userId;
      this.username = username;
      this.email = email;
      this.phoneNumber = phoneNumber;
      this.name = name;
      this.avatar = avatar;
      this.background = background;
      this.bio = bio;
      this.darkMode = darkMode;
      this.accountSetup = accountSetup;
      this.acceptTermsOfService = acceptTermsOfService;
      this.notificationsRepostWithComment = notificationsRepostWithComment;
      this.notificationsPushNotifications = notificationsPushNotifications;
      this.notificationsReaction = notificationsReaction;
      this.notificationsFollowRequest = notificationsFollowRequest;
      this.notificationsFollowAccepted = notificationsFollowAccepted;
      this.notificationsFollow = notificationsFollow;
      this.notificationsMention = notificationsMention;
      this.notificationsRepost = notificationsRepost;
      this.notificationsComment = notificationsComment;
      this.notificationsVideoPublished = notificationsVideoPublished;
      this.darkMode = darkMode;
    },
    async refreshUserState() {
      try {
        const {
          data: { data: userData },
        } = await apiBackendAuthAxios.get("/user");

        const { data: settingsData } = await apiBackendAuthAxios.get(
          "/user/settings"
        );

        let notificationsRepostWithComment = false;
        let notificationsPushNotifications = false;
        let notificationsReaction = false;
        let notificationsFollowRequest = false;
        let notificationsFollowAccepted = false;
        let notificationsFollow = false;
        let notificationsMention = false;
        let notificationsRepost = false;
        let notificationsComment = false;
        let notificationsVideoPublished = false;

        let darkMode = false;
        let followers: boolean;
        let following: boolean;
        settingsData.forEach((setting) => {
          switch (setting.name) {
            case "notifications_repost_with_comment":
              notificationsRepostWithComment = setting.value === "true";
              break;
            case "notifications_push_notifications":
              notificationsPushNotifications = setting.value === "true";
              break;
            case "notifications_reaction":
              notificationsReaction = setting.value === "true";
              break;
            case "notifications_follow_request":
              notificationsFollowRequest = setting.value === "true";
              break;
            case "notifications_follow_accepted":
              notificationsFollowAccepted = setting.value === "true";
              break;
            case "notifications_follow":
              notificationsFollow = setting.value === "true";
              break;
            case "notifications_mention":
              notificationsMention = setting.value === "true";
              break;
            case "notifications_repost":
              notificationsRepost = setting.value === "true";
              break;
            case "notifications_comment":
              notificationsComment = setting.value === "true";
              break;
            case "plavtv_notifications_video_published":
              notificationsVideoPublished = setting.value === "true";
              break;
            case "dark_mode":
              darkMode = setting.value === "true";
              break;
          }
        });

        this.loading = false;
        this.userId = userData.ulid;
        this.username = userData.username;
        this.email = userData.email;
        this.phoneNumber = userData.phone_number;
        this.name = userData.name;
        this.avatar = userData.avatar;
        this.background = userData.background;
        this.bio = userData.bio;
        this.darkMode = userData.dark_mode;
        this.accountSetup = userData.accountSetup;
        this.acceptTermsOfService = userData.acceptTermsOfService;
        this.followers = userData.followers;
        this.following = userData.following;

        this.notificationsRepostWithComment = notificationsRepostWithComment;
        this.notificationsPushNotifications = notificationsPushNotifications;
        this.notificationsReaction = notificationsReaction;
        this.notificationsFollowRequest = notificationsFollowRequest;
        this.notificationsFollowAccepted = notificationsFollowAccepted;
        this.notificationsFollow = notificationsFollow;
        this.notificationsMention = notificationsMention;
        this.notificationsRepost = notificationsRepost;
        this.notificationsComment = notificationsComment;
        this.notificationsVideoPublished = notificationsVideoPublished;
        this.notificationsVideoPublished = false;
        this.darkMode = darkMode;
      } catch (e) {
        /* empty */
      }
    },
    toggleDarkMode() {
      this.darkMode = !this.darkMode;
    },
  },
  persist: false,
  share: {
    enable: true,
    initialize: true,
  },
});
